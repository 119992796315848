import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, mapTo, throwError, } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    inventoryUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/inventory.json`;
    supplierUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/supplier.json`;
    projectUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/project.json`;
    productUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/product.json`;
    stockAvailableUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/stockAvailable.json`;
    stockIdUrl = `https://auraconst-e5e30-default-rtdb.firebaseio.com/stockAvailable`;

    constructor(private http:HttpClient) {}

    create(data: any) {
        return this.http.post(`${this.inventoryUrl}`, data).pipe(
           mapTo(true),
          catchError(error => {
            return error;
          })
        );
      }
      createStockAvail(data: any) {
        return this.http.post(`${this.stockAvailableUrl}`, data).pipe(
           mapTo(true),
          catchError(error => {
            return error;
          })
        );
      }
      getStock(): Observable<any> {
        return this.http.get(this.stockAvailableUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      get(): Observable<any> {
        return this.http.get(this.inventoryUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getSupplier(): Observable<any> {
        return this.http.get(this.supplierUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getProject(): Observable<any> {
        return this.http.get(this.projectUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getProduct(): Observable<any> {
        return this.http.get(this.productUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      update(data:any,id: any): Observable<any | boolean> {
        return this.http.put(`${this.stockIdUrl}/${id}.json`,data).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      getById(id: any): Observable<any | boolean> {
        return this.http.get(`${this.stockIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      deleteById(id: any): Observable<any | boolean> {
        return this.http.delete(`${this.stockIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
}