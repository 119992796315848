<div class="form-container">
    <button class="back-button" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Update Stock</h2>
    <form [formGroup]="stockForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="companyName">Company Name<span class="requried-field">*</span></label>
          <input
            type="text"
            readonly
            placeholder="Enter companyName"
            class="form-control"
            formControlName="companyName"
            [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors }"
          />
          <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
            <div *ngIf="f['companyName'].errors['required']">Company Name is required</div>
          </div>
        </div>
  
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="projectName">Project <span class="requried-field">*</span></label>
          <ng-autocomplete
            [data]="projectName"
            [searchKeyword]="keyword1"
            placeholder="Enter the Project Name"
            (selected)="selectEvent1($event)"
            (inputChanged)="onChangeSearch1($event)"
            (inputFocused)="onFocused1($event)"
            historyIdentifier="projectName"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
          ></ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.projectName"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
          <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
            <div *ngIf="f['projectName'].errors['required']">ProjectName is required</div>
          </div>
        </div>
  
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="categoryName">Category Name<span class="requried-field">*</span></label>
          <ng-autocomplete
            [data]="categoryName"
            [searchKeyword]="keyword2"
            placeholder="Enter the Category Name"
            (selected)="selectEvent2($event)"
            (inputChanged)="onChangeSearch2($event)"
            (inputFocused)="onFocused2($event)"
            historyIdentifier="categoryName"
            [itemTemplate]="itemTemplate1"
            [notFoundTemplate]="notFoundTemplate"
          ></ng-autocomplete>
          <ng-template #itemTemplate1 let-item>
            <a [innerHTML]="item.categoryName"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
          <div *ngIf="submitted && f['categoryName'].errors" class="invalid-feedback">
            <div *ngIf="f['categoryName'].errors['required']">Category Name is required</div>
          </div>
        </div>
  
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="incQuantity">Increase Quantity<span class="requried-field">*</span></label>
          <input
            type="number"
            id="incQuantity"
            placeholder="Enter incQuantity"
            class="form-control"
            formControlName="incQuantity"
            [ngClass]="{ 'is-invalid': submitted && f['incQuantity'].errors }"
          />
          <div *ngIf="submitted && f['incQuantity'].errors" class="invalid-feedback">
            <div *ngIf="f['incQuantity'].errors['required']">Increase Quantity is required</div>
          </div>
        </div>
  
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="decQuantity">Decrease Quantity<span class="requried-field">*</span></label>
          <input
            type="number"
            id="decQuantity"
            placeholder="Enter decQuantity"
            class="form-control"
            formControlName="decQuantity"
            [ngClass]="{ 'is-invalid': submitted && f['decQuantity'].errors }"
          />
          <div *ngIf="submitted && f['decQuantity'].errors" class="invalid-feedback">
            <div *ngIf="f['decQuantity'].errors['required']">Decrease Quantity is required</div>
          </div>
        </div>
      </div>
  
      <div class="formbutton">
        <button type="submit" class="btn btn-primary" (click)="onSubmit(stockForm.value)">Submit</button>
        <button type="button" class="btn btn-danger btn2" (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  