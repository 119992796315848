import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { DataService } from '../data.service';
import { NotificationService } from '../../../services/notificationService';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  siteStatusData: string[] = ['On-going','Started','Completed','Stopped',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  stockForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
   keyword1 = 'projectName';
   keyword2 = 'categoryName';
   categoryName:any[]=[];
   projectName:any[]=[];
   id:any;
  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {

    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }


    this.stockForm= this.formBuilder.group({
      companyName: ["Aura Contrivers"],
      projectName: ['', Validators.required],
      categoryName: ['',Validators.required],
      incQuantity:['',Validators.required],
      decQuantity:['',Validators.required]
     
      
    });
 
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.stockForm.patchValue({
      companyName:data.companyName,
      projectName:data.projectName,
      categoryName:data.categoryName,
      incQuantity:data.incQuantity,
      decQuantity:data.decQuantity
    })
  }

  goBack() {
    this.router.navigate(['/dashboard/inventory']);
  }
 
  get f() {
    return this.stockForm.controls;
  }

  onSubmit(data: any) {
    if (this.stockForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn=moment().format();
    data.productFrom = "stock";
    data.quantity = Number(data.incQuantity) - Number(data.decQuantity);

    

    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/generalUnit'])
    })
  }
  onCancel(){
    this.stockForm.reset()
  }

  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
   
    this.stockForm.patchValue({
      projectName:item.projectName
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProject().subscribe((res:any)=>{
      this.projectName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
        return this.projectName.filter( val  =>   val.toLowerCase().includes(search));
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }

  selectEvent2(item:any) {
    // do something with selected item
    console.log(item)
   
    this.stockForm.patchValue({
      categoryName:item.categoryName,
    })
  }
 
  onChangeSearch2(search: string) {
    console.log(search);
    search = search.toLowerCase();

    this.data.getProduct().subscribe((res: any) => {
        this.categoryName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
        console.log(this.categoryName);
        // Ensure filtering logic works correctly
        return this.categoryName.filter(val => val.categoryName.toLowerCase().includes(search));
        
    });
}


  onFocused2(e:any) {
    console.log(e)
    // do something
  }
}
