<div class="container-fluid">
    <h3>Filters</h3>
    <div class="filters">
        
      
        <label for="supplier">Category</label>
        <input type="text" class="dropdown"/>
        <label for="supplier">Name</label>
        <input type="text" class="dropdown"/>
        <label for="supplier">Product Name</label>
        <input type="text" class="dropdown"/>
        <label for="category">Page Size</label>
        
        <select id="category" class="dropdown">
            <!-- Add options here -->
        </select>
        <button id="addButton" class="btn btn-primary add-button" (click)="stockAvailable()"><mat-icon>add_to_queue</mat-icon></button>
    </div>
    <!-- <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        <th>Project Name</th>
        <th>User Name</th>
        <th>Total Amount</th>
        <th>Products</th>
        <th>Action</th> 
        </tr>
        </thead>
        <tbody >
            <tr *ngFor="
            let element of inventoryList
            
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.projectName}}</td>
              <td>{{element.userName}}</td>
              <td>{{element.totalAmount}}</td>
              <td>
                <table>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of element.productDetails">
                      <td>{{product.categoryName}}</td>
                      <td>{{product.quantity}}</td>
                    </tr>
                  </tbody>
                 
                </table>
               
                
              </td>
              <td>
              
                <mat-icon (click)="onEdit(element._id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element._id)" >delete</mat-icon>
               
              </td>
        </tr>
        
            </tbody>
            </table>
     </div> -->
    
      <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        <th>Project Name</th>
        <th>User Name</th>
        <th>Product From</th>
        <th>Product Name</th>
        <th>Quantity</th>
        <th>Status</th>
        <th>Action</th> 
        </tr>
        </thead>
        <tbody >
            <tr *ngFor="
            let element of stockList
            
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.projectName}}</td>
              <td>{{element.userName}}</td>
              <td>{{element.productFrom}}</td>
              <td>
              {{element.categoryName}}
                <!-- <ul  *ngFor="let product of element.productDetails">
                  {{product.categoryName}} <label><h6>ProductName</h6></label> <br> 
                  {{product.quantity}}<label><h6>Quantity</h6></label>
                </ul> -->
                
              </td>
              <td>
                {{element.quantity}}<label style="font-size: xx-small;">totalQuantity</label>|
                {{element.incQuantity}} <label style="font-size: xx-small;">increase</label>|{{element.decQuantity}}<label style="font-size: xx-small;">decrease</label></td>
                <td>
                  <div class="example-button-container">
                    <mat-icon
              class="status-icon"
              style="width: 30%;"
              [style.color]="element.isActive ? 'green' : 'red'"
              (click)="toggleStatus(element)"
            >
              {{ element.isActive ? 'toggle_on' : 'toggle_off' }}
            </mat-icon>
                  </div>
                </td>  
                <td>
              
                <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
               
              </td>
        </tr>
        
            </tbody>
            </table>
     </div>
</div>