import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { InventoryComponent } from "./inventory.component";
import { StockAvailableComponent } from "./stock-available/stock-available.component";
import { EditComponent } from "./edit/edit.component";







const routes: Routes = [
  {
    path: "",
    component:InventoryComponent
  },
  {
    path:"stockAvailable",
    component:StockAvailableComponent
  },
  {
    path:"edit",
    component:EditComponent
  }
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryRoutingModule {}
