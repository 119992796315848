import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { NotificationService } from '../../services/notificationService';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.scss'
})
export class InventoryComponent {
  inventoryList:any[]=[];
  productList:any[]=[];
  stockList:any[]=[];
  stockAvail:any[]=[];
  defaultPagination: string | undefined = undefined;
  page: number = 1;
  _itemsPerPage: any = "25";
  isChecked: boolean | undefined = false;
  checkedIdList: any[] = [];
  id: string = "";
  selectedId: string[] = [];
  constructor(private router:Router,
    private data:DataService,
    private toastr:NotificationService
  ) { }

  ngOnInit() {
    this.data.get().subscribe((val:any)=>{
      if (val) {
        this.inventoryList = Object.keys(val).map(key => ({ id: key, ...val[key] }));
        console.log(this.inventoryList)
        this.inventoryList.forEach((res:any)=>{
          this.productList.push(res.productDetails)
          console.log(this.productList,"prd")
        })
      }
      })
     this.getAll()
  }

  getAll(){
    this.data.getStock().subscribe((val:any)=>{
      if (val) {
        this.stockList = Object.keys(val).map(key => ({ id: key, ...val[key] }));
        console.log(this.inventoryList)
        this.stockList.forEach((res:any)=>{
          this.stockAvail.push(res.productDetails)
        })
      }
      })
  }

  stockAvailable(){
    this.router.navigate(['/dashboard/inventory/stockAvailable']);
  }
  onDelete(_id:string) {
    this.data.deleteById(_id).subscribe(()=>{
      this.toastr.showError("Deleted","Successfully!!")
      this.refreshPage();
    })
  }

  onEdit(_id:string) {
    this.router.navigate(['/dashboard/inventory/edit'], {
      queryParams: { id: _id }

    });
  }

  refreshPage(){
    this.getAll()
  }

  toggleStatus(element: any): void {
    console.log(element)
    element.isActive = !element.isActive;
    
    // Here you can also make an API call to update the status in your backend
  }

}
